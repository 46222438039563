import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Chip,
  CircularProgress,
  Backdrop
} from '@mui/material';
import { ArrowBack as ArrowBackIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import indexedDBService from '../services/indexedDBService';

const LocationDetails = () => {
  const { locationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const locationData = location.state?.location;
  const [bookingHistory, setBookingHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadLocationData = async () => {
      setIsLoading(true);
      try {
        const clients = await indexedDBService.getAllClients();
        const bookings = [];
        
        clients.forEach(client => {
          client.opportunities?.forEach(opp => {
            if (opp.Location_Name__r?.Name === locationData.name) {
              bookings.push({
                id: opp.Id || Math.random().toString(),
                date: new Date(opp.CloseDate).toLocaleDateString(),
                clientName: client.clientName || 'N/A',
                enquiryName: opp.Name || 'N/A',
                revenue: Number(opp.Total_Hire_Fees__c) || 0,
                grossProfit: Number(opp.Gross_Profit__c) || 0,
                brand: opp.Brand__c || 'N/A',
                type: opp.Type || 'N/A',
                rawDate: new Date(opp.CloseDate)
              });
            }
          });
        });

        setBookingHistory(bookings.sort((a, b) => b.rawDate.getTime() - a.rawDate.getTime()));
      } catch (error) {
        console.error('Error loading location data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (locationData?.name) {
      loadLocationData();
    }
  }, [locationData]);

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      minWidth: 120,
      sortComparator: (v1, v2, param1, param2) => {
        const date1 = param1?.row?.rawDate ? param1.row.rawDate.getTime() : 0;
        const date2 = param2?.row?.rawDate ? param2.row.rawDate.getTime() : 0;
        return date2 - date1;
      }
    },
    {
      field: 'clientName',
      headerName: 'Client',
      flex: 1.5,
      minWidth: 200,
    },
    {
      field: 'enquiryName',
      headerName: 'Enquiry Name',
      flex: 2,
      minWidth: 250,
    },
    {
      field: 'brand',
      headerName: 'Brand',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'revenue',
      headerName: 'Revenue',
      type: 'number',
      flex: 1,
      minWidth: 130,
      valueFormatter: (params) => `$${Number(params).toLocaleString()}`,
    },
    {
      field: 'grossProfit',
      headerName: 'Gross Profit',
      type: 'number',
      flex: 1,
      minWidth: 130,
      valueFormatter: (params) => `$${Number(params).toLocaleString()}`,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      minWidth: 70,
      renderCell: (params) => (
        <IconButton 
          size="small"
          onClick={() => {
            const sfBaseUrl = process.env.REACT_APP_SALESFORCE_URL || 'https://purelocations.lightning.force.com';
            window.open(`${sfBaseUrl}/lightning/r/Opportunity/${params.row.id}/view`, '_blank');
          }}
        >
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  const getRevenueData = () => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    // Initialize monthly data
    const monthlyData = new Map(
      months.map(month => [month, {
        month,
        revenue: 0,
        bookings: 0
      }])
    );

    // Process booking history using rawDate
    bookingHistory.forEach(booking => {
      const monthKey = months[booking.rawDate.getMonth()];
      const data = monthlyData.get(monthKey);
      
      // Accumulate revenue and increment booking count
      data.revenue += booking.revenue;
      data.bookings += 1;
    });

    // Return data in chronological order (Jan-Dec)
    return months.map(month => monthlyData.get(month));
  };

  // Add these statistical helper functions
  const calculateStandardDeviation = (values) => {
    const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
    const squareDiffs = values.map(value => Math.pow(value - mean, 2));
    const variance = squareDiffs.reduce((sum, value) => sum + value, 0) / values.length;
    return Math.sqrt(variance);
  };

  const getAverageWithoutOutliers = (values) => {
    if (values.length === 0) return 0;
    if (values.length === 1) return values[0];

    const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
    const stdDev = calculateStandardDeviation(values);
    
    // Filter out values more than 2 standard deviations from mean
    const filteredValues = values.filter(value => 
      Math.abs(value - mean) <= 2 * stdDev
    );

    // Return average of remaining values
    return filteredValues.length > 0 
      ? Math.round(filteredValues.reduce((sum, value) => sum + value, 0) / filteredValues.length)
      : 0;
  };

  const getAverageMetrics = () => {
    if (bookingHistory.length === 0) return { avgRevenue: 0, avgProfit: 0 };

    const revenues = bookingHistory.map(booking => booking.revenue);
    const profits = bookingHistory.map(booking => booking.grossProfit);

    return {
      avgRevenue: getAverageWithoutOutliers(revenues),
      avgProfit: getAverageWithoutOutliers(profits)
    };
  };

  if (!locationData) {
    return <Typography>Location not found</Typography>;
  }

  return (
    <Box>
      {/* Header */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton onClick={() => navigate('/locations')} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ flex: 1 }}>{locationData.name}</Typography>
          <IconButton 
            onClick={() => {
              const sfBaseUrl = process.env.REACT_APP_SALESFORCE_URL || 'https://purelocations.lightning.force.com';
              window.open(`${sfBaseUrl}/lightning/r/Account/${locationData.accountId}/view`, '_blank');
            }}
            title="Open in Salesforce"
          >
            <OpenInNewIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Chip 
            label={`Design: ${locationData.design}`} 
            sx={{ backgroundColor: '#0468BF', color: 'white' }} 
          />
          <Chip 
            label={`Tier: ${locationData.locationTier} `}
            sx={{ backgroundColor: '#BF048DFF', color: 'white' }} 
          />
          <Chip 
            label={`Status: ${locationData.status} `} 
            sx={{ 
              backgroundColor: locationData.status === 'Active Location' ? '#4caf50' : '#ff9800',
              color: 'white' 
            }} 
          />
        </Box>

        {/* Average Metrics */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card sx={{ 
              background: 'linear-gradient(135deg, #aa57bf 0%, #7d20a3 100%)',
              color: 'white',
              height: '100%'
            }}>
              <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}>
                <Typography variant="subtitle2" gutterBottom>
                  Average Revenue per Booking
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  ${getAverageMetrics().avgRevenue.toLocaleString()}
                </Typography>
                <Typography variant="caption" sx={{ mt: 1, opacity: 0.9 }}>
                  Based on {bookingHistory.length} bookings
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ 
              background: 'linear-gradient(135deg, #fb991f 0%, #f06f04 100%)',
              color: 'white',
              height: '100%'
            }}>
              <CardContent sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}>
                <Typography variant="subtitle2" gutterBottom>
                  Average Profit per Booking
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  ${getAverageMetrics().avgProfit.toLocaleString()}
                </Typography>
                <Typography variant="caption" sx={{ mt: 1, opacity: 0.9 }}>
                  Based on {bookingHistory.length} bookings
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3}>
        {/* Revenue Chart */}
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Revenue & Booking Trends</Typography>
              <Box sx={{ height: 400 }}>
                <LineChart
                  dataset={getRevenueData()}
                  margin={{ top: 20, right: 40, bottom: 80, left: 70 }}
                  grid={{ vertical: true, horizontal: true }}
                  xAxis={[{ 
                    dataKey: 'month',
                    scaleType: 'band',
                  }]}
                  yAxis={[
                    { 
                      id: 'revenue',
                      scaleType: 'linear',
                      valueFormatter: (value) => `$${value.toLocaleString()}`,
                      label: '',
                    },
                    {
                      id: 'bookings',
                      scaleType: 'linear',
                      position: 'right',
                      label: 'Number of Bookings',
                    }
                  ]}
                  series={[
                    {
                      dataKey: 'revenue',
                      label: 'Monthly Revenue',
                      yAxisKey: 'revenue',
                      color: '#0468BF',  // Patagonia blue
                      showMark: false,
                    },
                    {
                      dataKey: 'bookings',
                      label: 'Number of Bookings',
                      yAxisKey: 'bookings',
                      color: '#04BF55',  // Patagonia green
                      showMark: false,
                    }
                  ]}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { vertical: 'bottom', horizontal: 'middle' },
                      padding: { top: 20, bottom: 0, left: 0, right: 0 },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Booking History */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Booking History</Typography>
              <Box sx={{ height: 600 }}>
                <DataGridPro
                  rows={bookingHistory}
                  columns={columns}
                  density="comfortable"
                  pagination
                  initialState={{
                    pagination: { pageSize: 10 },
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default LocationDetails; 