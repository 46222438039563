import React, { useState, useEffect } from 'react';
import indexedDBService from '../services/indexedDBService';
import { 
  DataGridPro,
  GridToolbarContainer, 
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridToolbarQuickFilter,
  GridCsvExportMenuItem
} from '@mui/x-data-grid-pro';
import {
  Box,
  Typography,
  Avatar,
  Alert,
  Snackbar,
  Grid,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Divider
} from '@mui/material';
import { 
  Warning as WarningIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  OpenInNew as OpenInNewIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Clients = () => {
  const navigate = useNavigate();

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [atRiskClients, setAtRiskClients] = useState([]);
  const [dateRange, setDateRange] = useState(() => {
    const savedStartDate = localStorage.getItem('globalStartDate');
    const savedEndDate = localStorage.getItem('globalEndDate');
    return { 
      start: savedStartDate ? new Date(savedStartDate) : new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      end: savedEndDate ? new Date(savedEndDate) : new Date()
    };
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadData(dateRange.start, dateRange.end);

    const handleGlobalRefresh = () => {
      loadData(dateRange.start, dateRange.end);
    };

    const handleGlobalDateChange = (event) => {
      const { startDate, endDate } = event.detail;
      const newStartDate = new Date(startDate);
      const newEndDate = new Date(endDate);
      setDateRange({ start: newStartDate, end: newEndDate });
      loadData(newStartDate, newEndDate);
    };

    window.addEventListener('globalDataRefresh', handleGlobalRefresh);
    window.addEventListener('globalDateChange', handleGlobalDateChange);

    return () => {
      window.removeEventListener('globalDataRefresh', handleGlobalRefresh);
      window.removeEventListener('globalDateChange', handleGlobalDateChange);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('globalStartDate', dateRange.start.toISOString());
    localStorage.setItem('globalEndDate', dateRange.end.toISOString());
  }, [dateRange]);

  const calculatePeriodRevenue = (client, startDate, endDate) => {
    console.log(startDate, endDate);
    if (!client?.opportunities || !Array.isArray(client.opportunities)) return 0;
    
    const start = new Date(startDate);
    const end = new Date(endDate);

    return client.opportunities
      .filter(opp => {
        if (!opp.CloseDate) return false;
        const oppDate = new Date(opp.CloseDate);
        return oppDate >= start && oppDate <= end && opp.StageName === 'Closed Won';
      })
      .reduce((sum, opp) => {
        return sum + (Number(opp.Total_Hire_Fees__c) || 0);
      }, 0);
  };

  const calculateGrossProfit = (client, startDate, endDate) => {
    if (!client?.opportunities || !Array.isArray(client.opportunities)) return 0;
    
    const start = new Date(startDate);
    const end = new Date(endDate);

    return client.opportunities
      .filter(opp => {
        if (!opp.CloseDate) return false;
        const oppDate = new Date(opp.CloseDate);
        return oppDate >= start && oppDate <= end && opp.StageName === 'Closed Won';
      })
      .reduce((sum, opp) => {
        return sum + (Number(opp.Gross_Profit__c) || 0);
      }, 0);
  };

  const calculateTrend = (client, startDate, endDate) => {
    if (!startDate || !endDate || !client?.opportunities) {
      return { percentage: 0, trend: 'neutral', value: '0' };
    }

    const endDateTime = new Date(endDate).getTime();
    const startDateTime = new Date(startDate).getTime();
    const periodLength = endDateTime - startDateTime;

    const currentPeriodStart = new Date(endDateTime - periodLength);
    const previousPeriodStart = new Date(currentPeriodStart.getTime() - periodLength);

    const currentRevenue = client.opportunities.reduce((sum, opp) => {
      if (!opp.CloseDate) return sum;
      const oppDate = new Date(opp.CloseDate);
      if (opp.StageName === 'Closed Won' && 
          oppDate >= currentPeriodStart && 
          oppDate <= new Date(endDate)) {
        return sum + (Number(opp.Total_Hire_Fees__c) || 0);
      }
      return sum;
    }, 0);

    const previousRevenue = client.opportunities.reduce((sum, opp) => {
      if (!opp.CloseDate) return sum;
      const oppDate = new Date(opp.CloseDate);
      if (opp.StageName === 'Closed Won' && 
          oppDate >= previousPeriodStart && 
          oppDate < currentPeriodStart) {
        return sum + (Number(opp.Total_Hire_Fees__c) || 0);
      }
      return sum;
    }, 0);

    if (previousRevenue === 0) return { percentage: 0, trend: 'neutral', value: '0' };

    const percentage = ((currentRevenue - previousRevenue) / previousRevenue) * 100;
    return {
      percentage,
      trend: percentage > 0 ? 'up' : percentage < 0 ? 'down' : 'neutral',
      value: percentage.toFixed(1)
    };
  };

  const loadData = async (startDate, endDate) => {
    try {
      setLoading(true);

      const start = new Date(startDate || dateRange.start);
      const end = new Date(endDate || dateRange.end);

      console.log('Loading data for dates:', start, end);

      const allClients = await indexedDBService.getAllClients();
      
      const enrichedClients = allClients.map(client => {
        const totalHireFees = client.opportunities?.reduce((sum, opp) => {
          if (!opp.CloseDate) return sum;
          const oppDate = new Date(opp.CloseDate);
          if (opp.StageName === 'Closed Won' && oppDate >= start && oppDate <= end) {
            return sum + (Number(opp.Total_Hire_Fees__c) || 0);
          }
          return sum;
        }, 0) || 0;

        const grossProfit = client.opportunities?.reduce((sum, opp) => {
          if (!opp.CloseDate) return sum;
          const oppDate = new Date(opp.CloseDate);
          if (opp.StageName === 'Closed Won' && oppDate >= start && oppDate <= end) {
            return sum + (Number(opp.Gross_Profit__c) || 0);
          }
          return sum;
        }, 0) || 0;

        const wonCount = client.opportunities?.filter(opp => {
          if (!opp.CloseDate) return false;
          const oppDate = new Date(opp.CloseDate);
          return oppDate >= start && oppDate <= end && opp.StageName === 'Closed Won';
        }).length || 0;

        const lostCount = client.opportunities?.filter(opp => {
          if (!opp.CloseDate) return false;
          const oppDate = new Date(opp.CloseDate);
          return oppDate >= start && oppDate <= end && opp.StageName === 'Closed Lost';
        }).length || 0;

        const totalCount = wonCount + lostCount;
        const winRate = totalCount > 0 ? (wonCount / totalCount) * 100 : 0;

        const trend = calculateTrend(client, start, end);

        return {
          id: client.accountId,
          accountId: client.accountId,
          clientName: client.clientName || 'N/A',
          industry: client.industry || 'N/A',
          owner: {
            name: client.owner?.name || 'N/A',
            photoUrl: client.owner?.photoUrl || '',
            email: client.owner?.email || 'N/A'
          },
          totalHireFees: Number(totalHireFees),
          grossProfit: Number(grossProfit),
          trend,
          opportunities: client.opportunities || [],
          wonOpportunities: wonCount,
          lostOpportunities: lostCount,
          totalOpportunities: totalCount,
          winRate: winRate
        };
      });

      const sortedClients = enrichedClients.sort((a, b) => b.totalHireFees - a.totalHireFees);
      console.log('Final data being set:', sortedClients);
      setClients(sortedClients);

      if (startDate && endDate) {
        const riskClients = sortedClients
          .filter(client => client.totalHireFees > 0)
          .map(client => {
            // Calculate current and previous period values
            const endDateTime = new Date(endDate).getTime();
            const startDateTime = new Date(startDate).getTime();
            const periodLength = endDateTime - startDateTime;
            
            const currentPeriodStart = new Date(endDateTime - periodLength);
            const previousPeriodStart = new Date(currentPeriodStart.getTime() - periodLength);

            const currentRevenue = client.opportunities?.reduce((sum, opp) => {
              if (!opp.CloseDate) return sum;
              const oppDate = new Date(opp.CloseDate);
              if (opp.StageName === 'Closed Won' && 
                  oppDate >= currentPeriodStart && 
                  oppDate <= new Date(endDate)) {
                return sum + (Number(opp.Total_Hire_Fees__c) || 0);
              }
              return sum;
            }, 0) || 0;

            const previousRevenue = client.opportunities?.reduce((sum, opp) => {
              if (!opp.CloseDate) return sum;
              const oppDate = new Date(opp.CloseDate);
              if (opp.StageName === 'Closed Won' && 
                  oppDate >= previousPeriodStart && 
                  oppDate < currentPeriodStart) {
                return sum + (Number(opp.Total_Hire_Fees__c) || 0);
              }
              return sum;
            }, 0) || 0;

            // Calculate actual revenue drop
            const revenueDrop = previousRevenue > 0 ? 
              ((previousRevenue - currentRevenue) / previousRevenue) * 100 : 0;

            return {
              ...client,
              revenueDrop: revenueDrop || 0,
              currentRevenue: currentRevenue || 0,
              previousRevenue: previousRevenue || 0,
              revenueDropValue: (previousRevenue - currentRevenue) || 0
            };
          })
          .filter(client => (client.revenueDrop > 0 && client.previousRevenue > 0))
          .sort((a, b) => (b.revenueDropValue || 0) - (a.revenueDropValue || 0))
          .slice(0, 20);

        // Update the display of at-risk clients
        setAtRiskClients(riskClients);
      }
    } catch (err) {
      console.error('Error loading clients:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderTrendCell = (params) => {
    const trend = params.value || { percentage: 0, trend: 'neutral', value: '0' };
    const client = params.row;
    
    // Calculate current and previous period values
    const endDateTime = new Date(dateRange.end).getTime();
    const startDateTime = new Date(dateRange.start).getTime();
    const periodLength = endDateTime - startDateTime;
    
    const currentPeriodStart = new Date(endDateTime - periodLength);
    const previousPeriodStart = new Date(currentPeriodStart.getTime() - periodLength);

    const currentValue = client.opportunities?.reduce((sum, opp) => {
      if (!opp.CloseDate) return sum;
      const oppDate = new Date(opp.CloseDate);
      if (opp.StageName === 'Closed Won' && 
          oppDate >= currentPeriodStart && 
          oppDate <= new Date(dateRange.end)) {
        return sum + (Number(opp.Total_Hire_Fees__c) || 0);
      }
      return sum;
    }, 0) || 0;

    const previousValue = client.opportunities?.reduce((sum, opp) => {
      if (!opp.CloseDate) return sum;
      const oppDate = new Date(opp.CloseDate);
      if (opp.StageName === 'Closed Won' && 
          oppDate >= previousPeriodStart && 
          oppDate < currentPeriodStart) {
        return sum + (Number(opp.Total_Hire_Fees__c) || 0);
      }
      return sum;
    }, 0) || 0;

    const tooltipContent = `Current Period: $${currentValue.toLocaleString()}
Previous Period: $${previousValue.toLocaleString()}`;

    if (trend.trend === 'up') {
      return (
        <Tooltip title={tooltipContent} arrow>
          <Box sx={{ 
            color: 'success.main', 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%', 
            gap: 0.5 
          }}>
            <TrendingUpIcon fontSize="small" />
            <Typography variant="body2">+{trend.value}%</Typography>
          </Box>
        </Tooltip>
      );
    } else if (trend.trend === 'down') {
      return (
        <Tooltip title={tooltipContent} arrow>
          <Box sx={{ 
            color: 'error.main', 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%', 
            gap: 0.5 
          }}>
            <TrendingDownIcon fontSize="small" />
            <Typography variant="body2">{trend.value}%</Typography>
          </Box>
        </Tooltip>
      );
    }
    return (
      <Tooltip title="No previous period data available" arrow>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}>
          <Typography variant="body2">N/A</Typography>
        </Box>
      </Tooltip>
    );
  };

  const renderOwnerCell = (params) => {
    const owner = params.row.owner;
    const sfBaseUrl = process.env.REACT_APP_SALESFORCE_URL || 'https://purelocations.lightning.force.com';
    // Try the content delivery network path
    const photoUrl = owner?.photoUrl?.replace(
      'https://purelocations.file.force.com/profilephoto/',
      `${sfBaseUrl}/content/images/`
    );

    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Avatar 
          src={photoUrl} 
          sx={{ 
            width: 24, 
            height: 24,
            bgcolor: 'grey.400'
          }}
        >
          {owner?.name?.charAt(0) || 'N/A'}
        </Avatar>
        {params.value}
      </Box>
    );
  };

  const renderActionsCell = (params) => {
    const handleClick = () => {
      // Get the Salesforce base URL from environment variable or use a default
      const sfBaseUrl = process.env.REACT_APP_SALESFORCE_URL || 'https://purelocations.lightning.force.com';
      const accountId = params.row.accountId;
      
      // Construct and open the Salesforce URL
      const sfUrl = `${sfBaseUrl}/lightning/r/Account/${accountId}/view`;
      window.open(sfUrl, '_blank');
    };

    return (
      <IconButton 
        size="small" 
        onClick={handleClick}
        title="Open in Salesforce"
      >
        <OpenInNewIcon fontSize="small" />
      </IconButton>
    );
  };

  const renderClientNameCell = (params) => {
    const handleClick = () => {
      navigate(`/clients/${params.row.accountId}`, {
        state: { client: params.row }
      });
    };

    return (
      <Box
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          color: 'primary.main',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
      >
        {params.value}
      </Box>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem options={{
            fileName: 'clients_export',
            utf8WithBom: true,
            fields: ['clientName', 'owner', 'industry', 'totalHireFees', 'grossProfit']
          }} />
        </GridToolbarExportContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  const columns = [
    {
      field: 'clientName',
      headerName: 'Client Name',
      flex: 2,
      filterable: true,
      renderCell: renderClientNameCell,
      minWidth: 130,
    },
    {
      field: 'owner',
      headerName: 'Account Owner',
      flex: 1.5,
      filterable: true,
      renderCell: renderOwnerCell,
      valueGetter: (params) => {
        return params.name
      },
      minWidth: 130,
    },
    {
      field: 'industry',
      headerName: 'Industry Sector',
      flex: 1.5,
      filterable: true,
      minWidth: 130,
    },
    {
      field: 'totalHireFees',
      headerName: 'Revenue',
      flex: 1,
      type: 'number',
      renderCell: (params) => {
        const currentValue = params.value || 0;
        // Calculate previous period value using the same logic as trend calculation
        const endDateTime = new Date(dateRange.end).getTime();
        const startDateTime = new Date(dateRange.start).getTime();
        const periodLength = endDateTime - startDateTime;
        const currentPeriodStart = new Date(endDateTime - periodLength);
        const previousPeriodStart = new Date(currentPeriodStart.getTime() - periodLength);

        const previousValue = params.row.opportunities?.reduce((sum, opp) => {
          if (!opp.CloseDate) return sum;
          const oppDate = new Date(opp.CloseDate);
          if (opp.StageName === 'Closed Won' && 
              oppDate >= previousPeriodStart && 
              oppDate < currentPeriodStart) {
            return sum + (Number(opp.Total_Hire_Fees__c) || 0);
          }
          return sum;
        }, 0) || 0;

        const color = currentValue > previousValue ? '#4caf50' : 
                      currentValue < previousValue ? '#f44336' : '#757575';

        return (
          <Tooltip
            title={
              <Box sx={{ p: 1 }}>
                <Typography variant="body2">
                  Current Period: ${currentValue.toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  Previous Period: ${previousValue.toLocaleString()}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body2" sx={{ color }}>
                  {currentValue > previousValue ? 'Increase' : 'Decrease'}: {
                    previousValue > 0 
                      ? Math.abs(((currentValue - previousValue) / previousValue) * 100).toFixed(1)
                      : 100
                  }%
                </Typography>
              </Box>
            }
            arrow
          >
            <Box sx={{ color }}>
              ${currentValue.toLocaleString()}
            </Box>
          </Tooltip>
        );
      },
      minWidth: 130,
    },
    {
      field: 'grossProfit',
      headerName: 'Gross Profit',
      flex: 1,
      type: 'number',
      renderCell: (params) => {
        const currentValue = params.value || 0;
        // Calculate previous period value
        const endDateTime = new Date(dateRange.end).getTime();
        const startDateTime = new Date(dateRange.start).getTime();
        const periodLength = endDateTime - startDateTime;
        const currentPeriodStart = new Date(endDateTime - periodLength);
        const previousPeriodStart = new Date(currentPeriodStart.getTime() - periodLength);

        const previousValue = params.row.opportunities?.reduce((sum, opp) => {
          if (!opp.CloseDate) return sum;
          const oppDate = new Date(opp.CloseDate);
          if (opp.StageName === 'Closed Won' && 
              oppDate >= previousPeriodStart && 
              oppDate < currentPeriodStart) {
            return sum + (Number(opp.Gross_Profit__c) || 0);
          }
          return sum;
        }, 0) || 0;

        const color = currentValue > previousValue ? '#4caf50' : 
                      currentValue < previousValue ? '#f44336' : '#757575';

        return (
          <Tooltip
            title={
              <Box sx={{ p: 1 }}>
                <Typography variant="body2">
                  Current Period: ${currentValue.toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  Previous Period: ${previousValue.toLocaleString()}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body2" sx={{ color }}>
                  {currentValue > previousValue ? 'Increase' : 'Decrease'}: {
                    previousValue > 0 
                      ? Math.abs(((currentValue - previousValue) / previousValue) * 100).toFixed(1)
                      : 100
                  }%
                </Typography>
              </Box>
            }
            arrow
          >
            <Box sx={{ color }}>
              ${currentValue.toLocaleString()}
            </Box>
          </Tooltip>
        );
      },
      minWidth: 130,
    },
    {
      field: 'trend',
      headerName: 'Trend',
      flex: 1,
      renderCell: renderTrendCell,
      sortable: true,
      minWidth: 130,
    },
    {
      field: 'wonOpportunities',
      headerName: 'Total Won',
      flex: 1,
      type: 'number',
      sortable: true,
      minWidth: 100,
    },
    {
      field: 'lostOpportunities',
      headerName: 'Total Lost',
      flex: 1,
      type: 'number',
      sortable: true,
      minWidth: 100,
    },
    {
      field: 'totalOpportunities',
      headerName: 'Total',
      flex: 1,
      type: 'number',
      sortable: true,
      minWidth: 100,
    },
    {
      field: 'winRate',
      headerName: 'Win %',
      flex: 1,
      type: 'number',
      sortable: true,
      renderCell: (params) => {
        const winRate = params.row.winRate;

        // Function to get color based on percentage
        const getColorForPercentage = (pct) => {
          // Define color stops
          const colorStops = [
            { pct: 0, color: { r: 244, g: 67, b: 54 } },     // Red 0%
            { pct: 25, color: { r: 255, g: 152, b: 0 } },    // Orange 25%
            { pct: 50, color: { r: 255, g: 235, b: 59 } },   // Yellow 50%
            { pct: 75, color: { r: 139, g: 195, b: 74 } },   // Light Green 75%
            { pct: 100, color: { r: 76, g: 175, b: 80 } }    // Green 100%
          ];

          // Find the color stops that our percentage falls between
          for (let i = 1; i < colorStops.length; i++) {
            if (pct <= colorStops[i].pct) {
              const lower = colorStops[i - 1];
              const upper = colorStops[i];
              const range = upper.pct - lower.pct;
              const rangePct = (pct - lower.pct) / range;
              const pctLower = 1 - rangePct;
              const pctUpper = rangePct;

              const r = Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper);
              const g = Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper);
              const b = Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper);
              return `rgb(${r}, ${g}, ${b})`;
            }
          }
          return `rgb(76, 175, 80)`; // Default to green for 100%
        };

        const backgroundColor = getColorForPercentage(winRate);
        
        return (
          <Tooltip
            title={`${params.row.wonOpportunities} won out of ${params.row.totalOpportunities} total closed opportunities`}
            arrow
          >
            <Box sx={{ 
              backgroundColor,
              color: 'white',
              padding: '4px 8px',
              borderRadius: 0,
              minWidth: '60px',
              textAlign: 'center',
              fontWeight: 'bold',
              textShadow: '1px 1px 2px rgba(0, 0, 0, 1)',
              fontSize: '16px'
            }}>
              {winRate.toFixed(1)}%
            </Box>
          </Tooltip>
        );
      },
      minWidth: 100,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      renderCell: renderActionsCell,
      minWidth: 130,
    }
  ];

  const dataGridInitialState = {
    sorting: {
      sortModel: [{ field: 'totalHireFees', sort: 'desc' }],
    },
    pagination: {
      pageSize: 10,
    },
  };

  const filteredClients = clients.filter(client => {
    const searchRegex = new RegExp(searchTerm, 'i');
    return (
      searchRegex.test(client.clientName) ||
      searchRegex.test(client.industry) ||
      searchRegex.test(client.owner?.name || '')
    );
  });

  return (
    <Grid 
      container 
      spacing={3} 
      sx={{ 
        height: 'calc(100vh - 88px)',
        overflow: 'hidden'
      }}
    >
      <Grid item xs={12} md={10} sx={{ height: '100%' }}>
        <Box sx={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          overflow: 'hidden',
          backgroundColor: '#FFFFFF',
          padding: 2,
          borderRadius: 1,
          boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.1)'
        }}>
        

          <Box sx={{ 
            flex: 1,
            overflow: 'hidden'
          }}>
            <DataGridPro
              rows={filteredClients}
              columns={columns}
              loading={loading}
              density="comfortable"
              initialState={dataGridInitialState}
              pagination
              checkboxSelection
              disableRowSelectionOnClick
              slots={{ 
                toolbar: CustomToolbar 
              }}
              slotProps={{
                toolbar: {
                  printOptions: { 
                    hideFooter: true,
                    hideToolbar: true,
                  },
                  csvOptions: { 
                    fileName: 'clients_export',
                    utf8WithBom: true,
                  },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 }
                }
              }}
              sx={{
                height: '100%',
                '& .MuiDataGrid-main': { overflow: 'hidden' },
                // Add zebra striping
                '& .MuiDataGrid-row:nth-of-type(even)': {
                  backgroundColor: 'rgba(255, 255, 255, 0)',
                },
                // Hover effect
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.04)',
                },
                // Header styling
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#ffffff',
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontSize: '0.875rem',
                  fontWeight: 600,
                },
                // Add this for the top container row
                '& .MuiDataGrid-container--top [role=row]': {
                  backgroundColor: '#ffffff',
                },
                '& .MuiDataGrid-row': {
                  backgroundColor: '#FFFFFFBC',
                },
                // Cell styling
                '& .MuiDataGrid-cell': {
                 
                },
                // Selected row
                '& .MuiDataGrid-row.Mui-selected': {
                  backgroundColor: 'rgba(25, 118, 210, 0.08)',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.12)',
                  },
                },
                // Footer styling
                '& .MuiDataGrid-footerContainer': {
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  color: 'rgba(0, 0, 0, 0.87)',
                },
                // Add specific styles for each density mode
                '& .MuiDataGrid-row': {
                  '&.MuiDataGrid-row--densityCompact': {
                    maxHeight: '32px',
                  },
                  '&.MuiDataGrid-row--densityStandard': {
                    maxHeight: '52px',
                  },
                  '&.MuiDataGrid-row--densityComfortable': {
                    maxHeight: '64px',
                  },
                }
              }}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={2} sx={{ height: '100%' }}>
        <Card sx={{ height: '100%' }}>
          <CardContent sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            p: 0,
            '&:last-child': { pb: 0 } // Remove default padding bottom
          }}>
            <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
              <Typography variant="p" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <WarningIcon color="error" />
                Top 20 Clients at Risk
              </Typography>
            </Box>
            
            <Box sx={{ 
              flex: 1,
              overflow: 'auto',
              p: 2
            }}>
              {atRiskClients.map((client) => (
                <Box key={client.accountId} sx={{ mb: 2 }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      cursor: 'pointer',
                      color: 'primary.main',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                    onClick={() => navigate(`/clients/${client.accountId}`, {
                      state: { client: client }
                    })}
                  >
                    {client.clientName}
                  </Typography>
                  <Typography color="error" variant="caption" component="div">
                    Revenue drop: ${(client.revenueDropValue || 0).toLocaleString()}
                  </Typography>
                  <Typography color="error" variant="caption" component="div">
                    ({(client.revenueDrop || 0).toFixed(1)}% decrease)
                  </Typography>
                  <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
                    Previous: ${(client.previousRevenue || 0).toLocaleString()} → 
                    Current: ${(client.currentRevenue || 0).toLocaleString()}
                  </Typography>
                  <Divider sx={{ my: 2, borderColor: '#f4f4f4' }} />
                </Box>
              ))}
              
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default Clients; 